.curtain {
  width: 100%;
  height: 80px;
  height: 32px;
  background: linear-gradient(#fff 0%, #fff0 100%);
  position: absolute;
  bottom: -32px;
  left: 0;
}

.user-input {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 12px 16px;
}

.CustomerMessagesBox {
  max-width: 620px;
  flex-direction: column;
  flex: auto;
  margin: 0 auto;
  padding: 32px 8px;
  display: flex;
  overflow-x: hidden;
  overflow-y: auto;
}

.MessageBox {
  color: #222;
  width: 80%;
  border-radius: 8px;
  margin: 12px;
  padding: 16px 20px;
  box-shadow: 0 0 22px -16px #42445a;
}

.MessageBox .message {
  margin: 8px 0 20px;
  font-size: 16px;
  display: block;
}

.MessageBox .options {
  text-align: right;
}

.MessageBox .footer {
  text-align: right;
  border-top: 1px dotted #888;
  margin-top: 12px;
  padding-top: 4px;
}

.time {
  color: #121212;
  float: left;
  padding-top: 4px;
  font-size: 12px;
  font-style: italic;
}

.Customer {
  background: linear-gradient(#f8f8f8 0%, #fdfdfd 100%);
  border: 1px solid #ccc;
  align-self: flex-end;
}

.Agent {
  background: linear-gradient(#ffdeac 0%, #fbd9b2 100%);
  border: 1px solid #aaa;
  align-self: flex-start;
}

.System {
  color: #222;
  background: linear-gradient(#fafafa 0%, #fff 100%);
  border: 3px solid #ff7b0d;
  border-radius: 0;
  align-self: center;
}

.Info {
  color: #80878d;
  text-align: center;
  width: 50%;
  box-shadow: none;
  background-color: #f7f8dd;
  border-radius: 2px;
  margin: 2px auto;
  padding: 4px;
  font-size: 12px;
}

.Info .footer, .Info .options {
  display: none;
}

@media screen and (max-width: 700px) {
  .MessageBox {
    margin: 6px;
    padding: 8px 16px;
  }

  .MessageBox .message {
    font-size: 14px;
  }

  .MessageBox .footer {
    margin-top: 8px;
    padding-top: 2px;
    font-size: 12px;
  }

  .user-input {
    padding: 8px;
    font-size: 14px;
  }

  .time {
    padding-top: 2px;
    font-size: 10px;
  }

  .curtain {
    height: 16px;
    bottom: -16px;
  }
}

/*# sourceMappingURL=index.e1b81305.css.map */
