.curtain {
    position: absolute;
    left: 0;
    bottom: -80px;
    width: 100%;
    height: 80px;
    background: rgb(255, 255, 255);
    background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
    bottom: -32px;
    height: 32px;
}

.user-input {
    padding: 12px 16px;
    border-radius: 8px;   
    border: 1px solid #ccc;
}

.CustomerMessagesBox {
    max-width: 620px;
    padding-left: 8px;
    padding-right: 8px;
    margin: 0 auto;
    overflow-y: auto;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    overflow-x: hidden;
    padding-top: 32px;
    padding-bottom: 32px;
}

.MessageBox {
    color: #222;
    border-radius: 8px;
    padding: 16px 20px;
    width: 80%;
    margin: 12px;
    -webkit-box-shadow: 0px 0px 22px -16px rgba(66, 68, 90, 1);
    -moz-box-shadow: 0px 0px 22px -16px rgba(66, 68, 90, 1);
    box-shadow: 0px 0px 22px -16px rgba(66, 68, 90, 1);
}

.MessageBox .message {
    font-size: 16px;
    display: block;
    margin: 8px 0 20px;
}

.MessageBox .options {
    text-align: right;
}

.MessageBox .footer {
    border-top: 1px dotted #888;
    text-align: right;
    margin-top: 12px;
    padding-top: 4px;
}

.time {
    color: #121212;
    font-size: 12px;
    float: left;
    padding-top: 4px;
    font-style: italic;
}

.Customer {
    align-self: flex-end;
    background: linear-gradient(#f8f8f8 0%, #fdfdfd 100%);
    border: 1px solid #ccc;
}

.Agent {
    align-self: flex-start;
    background: linear-gradient(to bottom, #ffdeac 0%, #fbd9b2 100%);
    border: 1px solid #aaa;
}

.System {
    border-radius: 0;
    align-self: center;
    background: linear-gradient(#fafafa 0%, #fff 100%);
    color: #222;
    border: 3px solid #ff7b0d;
}

.Info {
    color: #80878d;
    background-color: #f7f8dd;
    font-size: 12px;
    text-align: center;
    padding: 4px;
    border-radius: 2;
    width: 50%;
    margin: 2px auto;
    box-shadow: none;
}

.Info .footer,
.Info .options {
    display: none;
}

@media screen and (max-width: 700px) {

    .MessageBox {
        padding: 8px 16px;
        margin: 6px;
    }

    .MessageBox .message {
        font-size: 14px;
    }

    .MessageBox .footer {
        margin-top: 8px;
        font-size: 12px;
        padding-top: 2px;
    }


    .user-input {
        padding: 8px;
        font-size: 14px;
    }

    .time {
        font-size: 10px;
        padding-top: 2px;
    }

    .curtain {
        bottom: -16px;
        height: 16px;
    }
}